import React from 'react';
import Navbar from './Navbar';  // Import your Navbar component
import Footer from './Footer';  // Import the Footer component
import './Contactus.css';       // Import the CSS file

const Contactus = () => {
  return (
    <div className="page-container" style={{ backgroundColor: '#935CEB', minHeight: '100vh' }}> {/* Set background color */}
      {/* Include Navbar Component */}
      <Navbar />

      {/* Main Contact Form Section */}
      <div className="content-wrap mt-4">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSd3wqBFxkav7nLClXqZs6u66XSGLRmdlJNZ6dkeAJBmJClwhQ/viewform?embedded=true"
          width="100%"
          height="1200"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Contact Form"
        >
          Loading…
        </iframe>
      </div>

      {/* Footer at the bottom */}
      <Footer />
    </div>
  );
};

export default Contactus;
