import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './TrainingCourse.css';
import Footer from './Footer';
import Navbar from './Navbar';

const courses = [
  {
    name: 'C Programming',
    description: 'Learn the basics of C programming, including data structures, algorithms, and more.',
  },
  {
    name: 'C++ Programming',
    description: 'Master object-oriented programming with C++, covering classes, inheritance, and polymorphism.',
  },
  {
    name: 'Java Programming',
    description: 'Become proficient in Java programming, from basic syntax to advanced features like multithreading.',
  },
  {
    name: 'Python Programming',
    description: 'Explore Python programming, including libraries for data science, web development, and more.',
  },
  {
    name: 'C Programming',
    description: 'Learn the basics of C programming, including data structures, algorithms, and more.',
  },
  {
    name: 'C++ Programming',
    description: 'Master object-oriented programming with C++, covering classes, inheritance, and polymorphism.',
  },
  {
    name: 'Java Programming',
    description: 'Become proficient in Java programming, from basic syntax to advanced features like multithreading.',
  },
  {
    name: 'Python Programming',
    description: 'Explore Python programming, including libraries for data science, web development, and more.',
  },
];

const TrainingCourse = () => {
  const navigate = useNavigate(); // Use navigate for navigation

  const handleContactUs = () => {
    navigate('/contact-us'); // Navigate to Contact Us page
  };

  return (
    <>
      <Navbar />
      <div className="page-container" style={{ backgroundColor: '#935CEB', minHeight: '100vh' }}> {/* Set background color */}
        <div className="content-wrap">
          <Container className="training-course-page">
          <a 
              className="text-black font-weight-bold d-flex justify-content-center mb-3" 
              style={{ 
                textShadow: '1px 1px 2px white, 0 0 25px white, 0 0 5px white, 0 0 5px white',
                fontSize: '22px',
                fontWeight: 'bold',
                margin:'5px'
              }}
            >
              TRAINING COURSES
            </a> {/* Optional: Change heading color */}
            <Row>
              {courses.map((course, index) => (
                <Col key={index} md={6} lg={4} className="mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Title>{course.name}</Card.Title>
                      <Card.Text>{course.description}</Card.Text>
                      {/* When button is clicked, navigate to Contact Us page */}
                      <Button variant="primary" onClick={handleContactUs}>
                        Contact Us
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TrainingCourse;
